
<template>
  <c-tabs v-if="filteredDetailTabs.length"
          :tabs="filteredDetailTabs"
          :class="b()"
          padding="800"
  >
    <template #specifications>
      <div v-if="product.variantLongTexts?.length">
        <div v-for="(information, index) in product.variantLongTexts"
             :key="index + information.type"
             :class="b('specification')"
        >
          <h3>{{ information.title }}</h3>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="information.text" class="wysiwyg"></div>
        </div>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="product.variantDescription"
           v-html="product.variantDescription"
           class="wysiwyg"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
    </template>
    <template #application-images>
      <div v-for="(application, index) in applicationImages"
           :key="index"
           :class="b('application')"
      >
        <div :class="b('wrapper')">
          <p>{{ application.description }}</p>
          <e-picture v-bind="application.medias" :ratio="undefined" :class="b('application-image')" />
        </div>
      </div>
    </template>
    <template #documents>
      <ul v-if="product.documents?.length" :class="b('product-documents-list')">
        <li v-for="(document, index) in product.documents"
            :key="index"
            :class="b('product-documents-item')"
        >
          <a :href="document.url"
             :class="b('product-documents-link')"
             target="_blank"
             rel="noopener noreferrer"
             download
          >
            <img v-if="document.icon"
                 :class="b('product-documents-icon')"
                 :src="document.icon"
                 :alt="document.description"
            >
            {{ document.description }}
          </a>
        </li>
      </ul>
      <div v-if="oxomiItemAttachments?.length">
        <c-oxomi-item-attachments :attachments="oxomiItemAttachments" />
      </div>
    </template>
    <template #media-links>
      <c-media-links v-if="productMediaLinks.length"
                     :media-links="productMediaLinks"
                     :product="product"
      />
    </template>
    <template #oxomi-catalogs>
      <div>
        <c-oxomi-item-pages :product="product" />
      </div>
    </template>
    <template #oxomi-videos>
      <div>
        <c-oxomi-item-videos :product="product" />
      </div>
    </template>
  </c-tabs>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useSessionStore from '@/stores/session';
  import useOxomiStore from '@/stores/oxomi';
  import ePicture from '@/elements/e-picture.vue';
  import cTabs, { Tab } from '@/components/c-tabs.vue';
  import cMediaLinks from '@/components/c-media-links.vue';
  import cOxomiItemPages from '@/components/c-oxomi-item-pages.vue';
  import cOxomiItemAttachments,
    { OxomiAttachmentResponseData, OxomiAttachement } from '@/components/c-oxomi-item-attachments.vue';
  import cOxomiItemVideos from '@/components/c-oxomi-item-videos.vue';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import mapCadDataToMediaLinks from '@/helpers/map-cad-data-to-media-links';
  import { ImageSrcset } from '@/types/image';
  import { AttachmentType } from '@/setup/globals';
  import { ProductMediaLink } from '@/types/product-media-link';
  import {
    Product,
  } from '@/types/product';
  import { ExistsResponse, ExistsOptions } from '@/plugins/oxomi';

  enum DetailTabs {
    Specifications = 'specifications',
    ApplicationImages = 'application-images',
    Documents = 'documents',
    MediaLinks = 'media-links',
    OxomiCatalogs = 'oxomi-catalogs',
    OxomiVideos = 'oxomi-videos',
  }

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  type Data = {
    oxomiExistsResponse?: ExistsResponse;
    oxomiAttachmentResponseData?: OxomiAttachmentResponseData;
  }

  type ApplicationImage = {
    description: string;
    medias: ImageSrcset;
  }

  /**
   * Generates product-specific c-tabs, each tab is linked to a predefined slot template.
   */
  export default defineComponent({
    name: 'c-product-detail-tabs',
    components: {
      cOxomiItemVideos,
      cOxomiItemAttachments,
      cOxomiItemPages,
      cTabs,
      ePicture,
      cMediaLinks,
    },

    props: {
      /**
       * Expects a product item to be passed.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    emits: {
      update: (payload: Tab[]) => !!payload,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },

    data(): Data {
      return {
        oxomiExistsResponse: undefined,
        oxomiAttachmentResponseData: undefined,
      };
    },

    computed: {
      /**
       * Returns a filtered array of tabs based on truthly conditions of exported product properties.
       */
      filteredDetailTabs(): Tab[] {
        const {
          variantLongTexts,
          variantDescription,
          documents,
          baseProductCADData,
        } = this.product;

        const definedTabs = [
          {
            title: this.$t('global.specification'),
            id: DetailTabs.Specifications,
            condition: !!variantLongTexts?.length || variantDescription,
          },
          {
            title: this.$t('c-product-detail-tabs.applicationImages'),
            id: DetailTabs.ApplicationImages,
            condition: this.applicationImages,
          },
          {
            title: this.$t('c-product-detail-tabs.dataSheet'),
            id: DetailTabs.Documents,
            condition: documents?.length || this.oxomiItemAttachments?.length,
          },
          {
            title: this.$t('c-product-detail-tabs.cadData'),
            condition: baseProductCADData && this.sessionStore.flags.showCadData && this.productMediaLinks,
            id: DetailTabs.MediaLinks,
          },
          {
            title: this.$t('c-product-detail-tabs.oxomiCatalogs'),
            condition: !!this.oxomiExistsResponse?.catalog,
            id: DetailTabs.OxomiCatalogs,
          },
          {
            title: this.$t('c-product-detail-tabs.oxomiVideos'),
            condition: !!this.oxomiExistsResponse?.video,
            id: DetailTabs.OxomiVideos,
          },
        ];

        return definedTabs.filter(tab => tab.condition)
          .map((tab, index) => ({
            title: tab.title,
            id: tab.id,
            active: index === 0,
          }));
      },

      applicationImages(): ApplicationImage[] | undefined {
        const { mediaForTabs } = this.product;

        if (!mediaForTabs || !mediaForTabs[0]?.medias?.length) {
          return undefined;
        }

        return mediaForTabs.map(media => ({
          description: media.description,
          medias: mapMediaContainerSrcSet(media),
        }));
      },

      productMediaLinks(): ProductMediaLink[] {
        const { baseProductCADData } = this.product;

        if (!baseProductCADData) {
          return [];
        }

        return mapCadDataToMediaLinks(baseProductCADData);
      },

      oxomiItemAttachments(): OxomiAttachement[] | undefined {
        return this.oxomiAttachmentResponseData?.attachments
          ?.filter(attachment => attachment.type === AttachmentType.DATASHEET
            || attachment.type === AttachmentType.CATALOG);
      },
    },
    watch: {
      filteredDetailTabs: {
        immediate: true,
        handler(tabs: Tab[]): void {
          this.$emit('update', tabs);
        },
      },
    },

    // beforeCreate() {},
    created() {
      const { oxomiPortal, brand, supplierCode } = this.product || {};

      useOxomiStore().apiItemAttachments({
        portal: oxomiPortal.portal,
        supplierCode,
        supplierNumber: brand?.supplierNumber,
      }).then((response) => {
        this.oxomiAttachmentResponseData = response.data || {};
      });

      if (oxomiPortal) {
        this.$oxomi(oxomiPortal).then(() => {
          const { code } = this.product || {};
          const { supplierNumber } = this.product.brand || {};
          const existsOptionsStub: Partial<ExistsOptions> = {};

          if (supplierCode && supplierNumber && supplierNumber !== 'own') {
            existsOptionsStub.itemNumber = supplierCode;
            existsOptionsStub.supplierNumber = supplierNumber;
          } else if (supplierNumber === 'own') {
            existsOptionsStub.item = code;
          } else {
            return;
          }

          window.oxomi.exists({
            ...existsOptionsStub,
            callback: this.onOxomiExistsCallback,
          });
        });
      }
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onOxomiExistsCallback(response: ExistsResponse): void {
        this.oxomiExistsResponse = response;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-detail-tabs {
    $this: &;

    &__specification {
      margin-bottom: variables.$spacing--40;
    }

    &__product-documents-item {
      padding-bottom: variables.$spacing--5;
    }

    &__product-documents-link {
      @include mixins.link--primary();

      display: inline-flex;
      gap: variables.$spacing--10;
      align-items: center;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__product-documents-icon {
      width: 20px;
      height: 20px;
    }

    &__application {
      #{$this}__wrapper {
        margin-bottom: variables.$spacing--70;

        @include mixins.media(md) {
          #{$this}__application-image {
            display: block;
            margin: auto;
            background-size: contain;
          }

          #{$this}__application-image img {
            width: auto;
            max-height: 50vh;
            margin: auto;
          }
        }
      }
    }

    .c-tabs__panel--oxomi-videos,
    .c-tabs__panel--oxomi-catalogs,
    .c-tabs__panel--media-links,
    .c-tabs__panel--documents {
      @include mixins.media($media: print) {
        display: none;
      }
    }
  }
</style>
