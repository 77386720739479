<template>
  <c-global-quick-add-to-cart v-if="showGlobalQuickAddToCart" />
  <c-iframe-modal />
  <c-product-stock-modal />
  <c-confirmation-modal />
  <c-wishlist-modal />
  <c-volume-price-modal />
  <c-substitute-products-modal />
  <c-cart-merge-modal />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cIframeModal from '@/components/c-iframe-modal.vue';
  import cConfirmationModal from '@/components/c-confirmation-modal.vue';
  import cVolumePriceModal from '@/components/c-volume-price-modal.vue';
  import cProductStockModal from '@/components/c-product-stock-modal.vue';
  import cSubstituteProductsModal from '@/components/c-substitute-products-modal.vue';
  import cWishlistModal from '@/components/c-wishlist-modal.vue';
  import cCartMergeModal from '@/components/c-cart-merge-modal.vue';
  import cGlobalQuickAddToCart from '@/components/c-global-quick-add-to-cart.vue';
  import useSessionStore from '@/stores/session';
  import useCheckoutStore from '@/stores/checkout';
  import useStructuredDataStore from '@/stores/structured-data';
  import {
    Layout,
    IS_STORAGE_AVAILABLE,
    StorageKey,
  } from '@/setup/globals';
  import searchResultPageUrl from '@/helpers/search-result-page-url';
  import { Mode } from '@/components/c-barcode-scanner.vue';

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
    structuredDataStore: ReturnType<typeof useStructuredDataStore>;
  }

  declare global {
    interface Window {
      mobileApp?: {
        isLoggedIn: boolean;
        addToCart(productCode: string, quantity: number, type?: 'qr' | 'ean'): Promise<void>;
        search(productCode: string, type?: 'qr' | 'ean'): void;
        login(): void;
        openScanner?(mode: Mode): void;
      };
    }
  }

  // type Data = {}

  /**
   * Contains the global rendered components and logic.
   */
  export default defineComponent({
    name: 'c-global',
    components: {
      cGlobalQuickAddToCart,
      cCartMergeModal,
      cWishlistModal,
      cSubstituteProductsModal,
      cProductStockModal,
      cVolumePriceModal,
      cConfirmationModal,
      cIframeModal,
    },

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        structuredDataStore: useStructuredDataStore(),
      };
    },
    // data(): Data {},

    computed: {
      userlikeEnabled(): boolean {
        const { flags, getIsLoggedIn } = this.sessionStore;
        const { userLikeEnabled, userLikeOnlyForLoggedInUsers } = flags;

        return userLikeEnabled && (!userLikeOnlyForLoggedInUsers || getIsLoggedIn);
      },

      showGlobalQuickAddToCart(): boolean {
        const { activeLayout } = this.sessionStore;

        const isCartOrCheckoutPage = activeLayout && [
          Layout.Cart,
          Layout.CheckoutDelivery,
          Layout.CheckoutConfirmation,
          Layout.CheckoutLogin,
          Layout.CheckoutPayment,
          Layout.CheckoutReview,
        ].includes(activeLayout);

        return this.$viewport.isLg && !isCartOrCheckoutPage;
      },
    },
    watch: {
      'userlikeEnabled': {
        handler(enabled) {
          if (enabled) {
            this.$userlike.mount();
          }
        },
        immediate: true,
      },

      'sessionStore.getIsLoggedIn': {
        handler() {
          this.updateUserDetailsStorage();
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    created() {
      this.$gtm.pushUserData(this.sessionStore.user);
      this.initMobileAppConfiguration();
      this.renderStructuredData();
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Renders LD+JSON passed via initial data.
       */
      renderStructuredData(): void {
        const { items } = this.structuredDataStore;

        if (Array.isArray(items) && items.length) {
          items.filter(Boolean).forEach((item) => {
            const element = document.createElement('script');

            element.setAttribute('type', 'application/ld+json');
            element.innerText = JSON.stringify(item);

            document.head.appendChild(element);
          });
        }
      },

      initMobileAppConfiguration(): void {
        window.mobileApp = {
          isLoggedIn: useSessionStore().getIsLoggedIn,
          addToCart: (productCode, quantity, type = 'qr') => new Promise<void>((resolve, reject) => {
            if (type === 'ean') {
              useCheckoutStore().apiAddToCartByEan({
                product: {
                  ean: productCode,
                },
                quantity,
                commissionId: useSessionStore().globalCommission?.id || '',
              }).then(() => {
                resolve();
              }).catch(() => {
                reject();
              });
            } else {
              useCheckoutStore().apiAddToCart({
                product: {
                  code: productCode,
                },
                quantity,
                commissionId: useSessionStore().globalCommission?.id || '',
              }).then(() => {
                resolve();
              }).catch(() => {
                reject();
              });
            }
          }),
          search: (productCode, type = 'qr') => {
            if (type === 'ean') {
              window.location.href = searchResultPageUrl(productCode, true);

              return;
            }

            window.location.href = searchResultPageUrl(productCode);
          },
          login: () => {
            const sessionStore = useSessionStore();

            if (!sessionStore.getIsLoggedIn) {
              window.location.href = sessionStore.links.loginLogoutLink.url;
            }
          },
        };
      },

      /**
       * Updates user details in the session storage.
       */
      updateUserDetailsStorage(): void {
        const sessionStore = useSessionStore();

        if (!IS_STORAGE_AVAILABLE) {
          return;
        }

        if (!sessionStore.getIsLoggedIn) {
          const keysToRemove = [
            StorageKey.UserLastname,
            StorageKey.UserFirstname,
            StorageKey.UserEmail,
            StorageKey.UserCustomerNumber,
          ];

          keysToRemove.forEach((key) => {
            window.sessionStorage.removeItem(key);
          });

          return;
        }

        const { user } = this.sessionStore;

        window.sessionStorage.setItem(StorageKey.UserLastname, user.lastName);
        window.sessionStorage.setItem(StorageKey.UserFirstname, user.firstName);
        window.sessionStorage.setItem(StorageKey.UserEmail, user.email);
        window.sessionStorage.setItem(StorageKey.UserCustomerNumber, user.customerId);
      },

    },
    // render() {},
  });
</script>
