<template>
  <div :class="b()">
    <c-scandit v-if="show" :mode="mode" @close="show = false" />
    <button :aria-label="$t('c-barcode-scanner.buttonScan')"
            :class="b('trigger')"
            class="app-qr-scan"
            type="button"
            @click="onClickOpen"
    >
      <e-icon icon="i-barcode-scan" size="25" />
    </button>
  </div>
</template>

<script lang="ts">
  import {
    defineAsyncComponent,
    defineComponent,
    PropType,
  } from 'vue';
  import eIcon from '@/elements/e-icon.vue';

  export enum Mode {
    Search = 'search',
    AddToCart = 'add-to-cart',
  }

  // type Setup = {}

  type Data = {
    show: boolean;
  }

  /**
   * Renders the barcode scanner button.
   */
  export default defineComponent({
    name: 'c-barcode-scanner',
    components: { cScandit: defineAsyncComponent(() => import('./c-scandit.vue')), eIcon },

    props: {
      /**
       * Expects the mode for the barcode scanner to be defined.
       */
      mode: {
        type: String as PropType<Mode>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    data(): Data {
      return {
        show: false,
      };
    },

    // computed: {},
    // watch: {},
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onClickOpen(): void {
        const { openScanner } = window.mobileApp || {};

        if (typeof openScanner === 'function') {
          openScanner(this.mode);

          return;
        }

        this.show = true;
      }
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-barcode-scanner {
    &__trigger {
      cursor: pointer;
    }
  }
</style>
