import { Stock } from '@/types/stock';
import { HybrisType, StockStatus, Icon } from '@/setup/globals';
import i18n from '@/setup/i18n';
import useSessionStore from '@/stores/session';

type IconMap = {
  [key in StockStatus]: string;
}

type LabelMap = {
  [key in StockStatus]: string;
}

export type StockState = {
  label: string;
  icon: string;
}

export const labelMap: LabelMap = {
  [StockStatus.InStock]: 'global.productStock.inStock',
  [StockStatus.AvailableIn1To2Days]: 'global.productStock.availableIn1To2Days',
  [StockStatus.AvailableIn3To5Days]: 'global.productStock.availableIn3To5Days',
  [StockStatus.AvailableIn1To2Weeks]: 'global.productStock.availableIn1To2Weeks',
  [StockStatus.AvailableIn3To4Weeks]: 'global.productStock.availableIn3To4Weeks',
  [StockStatus.DeliveryDateOnRequest]: 'global.productStock.deliveryDateOnRequest',
  [StockStatus.OutOfStock]: 'global.productStock.outOfStock',
};

export const iconMap: IconMap = {
  [StockStatus.InStock]: Icon.InStock,
  [StockStatus.AvailableIn1To2Days]: Icon.AvailableIn1To2Days,
  [StockStatus.AvailableIn3To5Days]: Icon.AvailableIn3To5Days,
  [StockStatus.AvailableIn1To2Weeks]: Icon.AvailableIn1To2Weeks,
  [StockStatus.AvailableIn3To4Weeks]: Icon.AvailableIn3To4Weeks,
  [StockStatus.DeliveryDateOnRequest]: Icon.DeliveryDateOnRequest,
  [StockStatus.OutOfStock]: Icon.OutOfStock,
};

export default function mapStockIndicator(stock?: Stock): StockState | undefined {
  const { getIsLoggedIn } = useSessionStore();

  if (stock?.type !== HybrisType.Stock || !stock.stockLevelStatus) {
    return undefined;
  }

  const { stockLevel } = stock || {};
  const { code } = stock.stockLevelStatus || {};
  const showStockLevel = code === StockStatus.InStock
    && import.meta.env.VITE_SHOW_DELIVERY_STOCK_LEVEL === '1'
    && stockLevel
    && getIsLoggedIn;

  const label = i18n.global.t(labelMap[code]);

  return {
    icon: iconMap[code],
    label: showStockLevel
      ? `${label} (${stockLevel} ${stock.unit})`
      : label
  };
}
