import { defineStore } from 'pinia';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import useSessionStore from '@/stores/session';
import { Store, HybrisType } from '@/setup/globals';
import { CheckoutData } from '@/types/checkout-data';
import { ApiResponse } from '@/types/api-response';
import { OrderEntry } from '@/types/order-entry';
import { Cart } from '@/types/cart';
import { CheckoutStep } from '@/types/checkout-step';
import { Product } from '@/types/product';
import { Country } from '@/types/country';
import { ContactPerson } from '@/types/contact-person';
import normalizeContactPerson from '@/helpers/normalize-contact-person';

interface RunningRequests {
  apiAddToCart: boolean;
  apiUpdateCartEntry: boolean;
  apiRemoveCartEntry: boolean;
  apiUpdateEntryText: boolean;
  apiRemoveCartEntries: boolean;
  apiCartImport: boolean;
  apiUpdateEntryCommissions: boolean;
  apiUpdateDeliveryMode: boolean;
  apiSelectDeliveryAddress: boolean;
  apiChangeDeliveryAddress: boolean;
  apiUpdateOrderReference: boolean;
  apiUpdateComment: boolean;
  apiUpdatePaymentMode: boolean;
  apiChangePaymentAddress: boolean;
  apiSubmitVoucher: boolean;
  apiAddWishlistToCart: boolean;
  apiAddToCartByEan: boolean;
  apiAddOrderToCart: boolean;
  apiCalculatePrices: boolean;
  apiFetchRetailers: boolean;
  apiInitializeDatatrans: boolean;
  apiClearCart: boolean;
}

interface State {

  /**
   * Holds the checkout data.
   */
  checkoutData?: CheckoutData;

  /**
   * Holds the cart object.
   * Is part of `checkoutData` but could be updated separately.
   */
  cart?: Cart;

  /**
   * Holds the checkout steps.
   * Can't be updated after initially set.
   */
  steps?: Readonly<CheckoutStep[]>;

  contactPerson?: ContactPerson;

  /**
   * Holds the running requests state.
   */
  runningRequests: RunningRequests;
}

export interface InitialData extends ApiResponse {
  data: {
    checkoutData: CheckoutData;
    contactPerson?: ContactPerson;
  };
}

export interface AddToCartPayload {
  product?: {
    code?: string;
    ean?: string;
  };
  quantity: number;
  commissionId: string;
  entryText?: string;
}

export interface UpdateCartEntryPayload {
  commissionId: string | null;
  entryNumber: number;
  product: Pick<Product, 'code'>;
  quantity: number;
}

export interface AddWishlistToCartPayload {
  code: string;
  commissionId: string;
}

export type AddOrderToCartPayload = {
  code: string;
  commissionId: string;
}

export interface UpdateDeliveryModePayload {
  code: string;
  deliveryDateTimes?: { 'formattedDate': string }[];
  completeDeliverySelected?: boolean;
}

export interface UpdatePaymentModePayload {
  id: string;
}

export interface SelectDeliveryAddressPayload {
  id: string;
}

export type SubmitVoucherPayload = {
  code: string;
}

export interface AddressPayload {
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  postalCode: string;
  town: string;
  country: Pick<Country, 'isocode'>;
  region: {
    isocodeShort: string;
  };
}

export interface UpdateEntryCommissionsPayload {
  commissionId: string;
  entryNumber: number;
}

export type RetailConnectPayload = {
  entries: {
    entryNumber: number;
    quantity: number;
    product: Pick<Product, 'code'>;
  }[];
}

export type RemoveCartEntriesPayload = {
  entries: {
    entryNumber: number;
    product: Pick<Product, 'code'>;
  }[];
}

const storeName = Store.Checkout;

export default defineStore(storeName, {
  state: () => {
    const initialData = window.initialData[storeName];
    const { checkoutData, contactPerson } = initialData?.data || {};

    const state: State = {
      checkoutData,
      cart: checkoutData?.cart,
      steps: checkoutData?.checkoutSteps || [],
      contactPerson: contactPerson ? normalizeContactPerson(contactPerson) : undefined,
      runningRequests: {
        apiAddToCart: false,
        apiUpdateCartEntry: false,
        apiRemoveCartEntry: false,
        apiUpdateEntryText: false,
        apiRemoveCartEntries: false,
        apiUpdateDeliveryMode: false,
        apiSelectDeliveryAddress: false,
        apiChangeDeliveryAddress: false,
        apiCartImport: false,
        apiUpdateEntryCommissions: false,
        apiUpdateOrderReference: false,
        apiUpdateComment: false,
        apiUpdatePaymentMode: false,
        apiChangePaymentAddress: false,
        apiSubmitVoucher: false,
        apiAddWishlistToCart: false,
        apiAddToCartByEan: false,
        apiAddOrderToCart: false,
        apiCalculatePrices: false,
        apiFetchRetailers: false,
        apiInitializeDatatrans: false,
        apiClearCart: false,
      },
    };

    return state;
  },

  getters: {
    /**
     * Returns the cart entries.
     */
    getCartEntries(): OrderEntry[] {
      const { entries } = this.cart || {};

      return Array.isArray(entries) ? entries : [];
    },

    /**
     * Returns the amount of entries in the cart.
     */
    getCartEntriesAmount(): number {
      return this.getCartEntries.length;
    },

    /**
     * Returns whether it is an OCI cart.
     */
    isOciCart(): boolean {
      return !!this.checkoutData?.ociCart;
    },
  },

  actions: {
    /**
     * Updates the checkout data in the state.
     */
    updateCheckoutData(checkoutData: CheckoutData): void {
      this.checkoutData = checkoutData;
      this.cart = checkoutData.cart;
    },

    /**
     * Response handling for requests that receive checkout data in the response.
     */
    handleCheckoutDataResponse(response: AxiosResponse, apiIdentifier: string): AxiosPromise {
      const { data } = response.data;
      const { checkoutData, cart } = data || {};

      if (checkoutData?.type === HybrisType.CheckoutData) {
        this.updateCheckoutData(checkoutData);

        return Promise.resolve(response);
      }

      if (cart?.type === HybrisType.Cart) {
        this.cart = cart;

        return Promise.resolve(response);
      }

      return Promise.reject(new Error(`API failure during "${apiIdentifier}" request.`));
    },

    /**
     * Adds a product to the cart.
     */
    apiAddToCart(payload: AddToCartPayload, sendCheckoutData = false): AxiosPromise {
      this.runningRequests.apiAddToCart = true;

      return this.$api.post(this.$api.getApiUrl('cartEntry'), payload, {
        headers: {
          sendCheckoutData,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiAddToCart'))
        .finally(() => {
          this.runningRequests.apiAddToCart = false;
        });
    },

    /**
     * Adds a product by an EAN number to the cart.
     */
    apiAddToCartByEan(payload: AddToCartPayload): AxiosPromise {
      this.runningRequests.apiUpdateCartEntry = true;

      return this.$api.post(this.$api.getApiUrl('cartEntryEan'), {
        ...payload,
        orderType: 'DF32',
      }, {
        headers: {
          sendCheckoutData: false,
        },
      })
        .then(response => this.handleCheckoutDataResponse(response, 'checkout/apiAddToCartByEan'))
        .finally(() => {
          this.runningRequests.apiUpdateCartEntry = false;
        });
    },

    /**
     * Updates a cart entry.
     */
    apiUpdateCartEntry(payload: UpdateCartEntryPayload): AxiosPromise {
      this.runningRequests.apiUpdateCartEntry = true;

      return this.$api.patch(this.$api.getApiUrl('cartEntry'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      })
        .then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateCartEntry'))
        .finally(() => {
          this.runningRequests.apiUpdateCartEntry = false;
        });
    },

    /**
     * Removes a cart entry.
     */
    apiRemoveCartEntry(payload: UpdateCartEntryPayload): AxiosPromise {
      this.runningRequests.apiRemoveCartEntry = true;

      return this.$api.delete(this.$api.getApiUrl('cartEntry'), {
        headers: {
          sendCheckoutData: true,
        },
        data: payload,
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateCartEntry'))
        .finally(() => {
          this.runningRequests.apiRemoveCartEntry = false;
        });
    },

    /**
     * Updates the entry text of a cart entry.
     */
    apiUpdateEntryText(entryNumber: number, entryText: string) {
      this.runningRequests.apiUpdateEntryText = true;

      return this.$api.put(this.$api.getApiUrl('cartEntryText'), {
        entryNumber,
        entryText,
      }, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateCartEntry'))
        .finally(() => {
          this.runningRequests.apiUpdateEntryText = false;
        });
    },

    /**
     * Removes a list of entries from the c art.
     */
    apiRemoveCartEntries(payload: RemoveCartEntriesPayload): AxiosPromise {
      this.runningRequests.apiRemoveCartEntries = true;

      return this.$api.delete(this.$api.getApiUrl('cart'), {
        headers: {
          sendCheckoutData: true,
        },
        data: payload,
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiRemoveCartEntries'))
        .finally(() => {
          this.runningRequests.apiRemoveCartEntries = false;
        });
    },

    /**
     * Uploads a CSV/DAT-file containing cart information.
     */
    apiCartImport(payload: FormData): AxiosPromise {
      this.runningRequests.apiCartImport = true;

      return this.$api.post(this.$api.getApiUrl('cartImport'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then((response) => {
        const { commissions } = response.data.data || {};

        if (Array.isArray(commissions)) {
          useSessionStore().commissions = commissions;
        }

        return this.handleCheckoutDataResponse(response, 'checkout/apiCartImport');
      })
        .finally(() => {
          this.runningRequests.apiCartImport = false;
        });
    },

    /**
     * Updates the commission for a list of entries.
     */
    apiUpdateEntryCommissions(payload: UpdateEntryCommissionsPayload[]): AxiosPromise {
      this.runningRequests.apiUpdateEntryCommissions = true;

      return this.$api.patch(this.$api.getApiUrl('commissionEntry'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateEntryCommissions'))
        .finally(() => {
          this.runningRequests.apiUpdateEntryCommissions = false;
        });
    },

    /**
     * Updates the delivery mode.
     */
    apiUpdateDeliveryMode(payload: UpdateDeliveryModePayload): AxiosPromise {
      this.runningRequests.apiUpdateDeliveryMode = true;

      return this.$api.put(this.$api.getApiUrl('deliveryMode'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateDeliveryMode'))
        .finally(() => {
          this.runningRequests.apiUpdateDeliveryMode = false;
        });
    },

    /**
     * Selects a delivery address from the possible addresses.
     */
    apiSelectDeliveryAddress(payload: SelectDeliveryAddressPayload): AxiosPromise {
      this.runningRequests.apiSelectDeliveryAddress = true;

      return this.$api.put(this.$api.getApiUrl('deliveryAddress'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiSelectDeliveryAddress'))
        .finally(() => {
          this.runningRequests.apiSelectDeliveryAddress = false;
        });
    },

    /**
     * Changes the current delivery address.
     */
    apiChangeDeliveryAddress(payload: AddressPayload): AxiosPromise {
      this.runningRequests.apiChangeDeliveryAddress = true;

      return this.$api.post(this.$api.getApiUrl('deliveryAddress'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiChangeDeliveryAddress'))
        .finally(() => {
          this.runningRequests.apiChangeDeliveryAddress = false;
        });
    },

    /**
     * Updates the order reference.
     */
    apiUpdateOrderReference(orderReference: string): AxiosPromise {
      const errorMessage = 'API failure during "checkout/apiUpdateOrderReference" request.';

      this.runningRequests.apiUpdateOrderReference = true;

      return this.$api.put(this.$api.getApiUrl('orderReference'), { value: orderReference }, {
        headers: {
          sendCheckoutData: true,
        },
      }, 'apiUpdateOrderReference')
        .then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdateOrderReference'))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return Promise.reject(error);
          }

          return Promise.reject(new Error(errorMessage));
        }).finally(() => {
          this.runningRequests.apiUpdateOrderReference = false;
        });
    },

    /**
     * Updates the b2b comment.
     */
    apiUpdateComment(comment: string): AxiosPromise {
      const errorMessage = 'API failure during "checkout/apiUpdateComment" request.';

      this.runningRequests.apiUpdateComment = true;

      return this.$api.put(this.$api.getApiUrl('orderComment'), { comment }, {
        headers: {
          sendCheckoutData: true,
        },
      }, 'apiUpdateComment')
        .catch((error) => {
          if (axios.isCancel(error)) {
            return Promise.reject(error);
          }

          return Promise.reject(new Error(errorMessage));
        }).finally(() => {
          this.runningRequests.apiUpdateComment = false;
        });
    },

    /**
     * Updates the payment mode.
     */
    apiUpdatePaymentMode(payload: UpdatePaymentModePayload): AxiosPromise {
      this.runningRequests.apiUpdatePaymentMode = true;

      return this.$api.put(this.$api.getApiUrl('paymentMode'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiUpdatePaymentMode'))
        .finally(() => {
          this.runningRequests.apiUpdatePaymentMode = false;
        });
    },

    /**
     * Changes the current payment address.
     */
    apiChangePaymentAddress(payload: AddressPayload): AxiosPromise {
      this.runningRequests.apiChangePaymentAddress = true;

      return this.$api.post(this.$api.getApiUrl('paymentAddress'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiChangePaymentAddress'))
        .finally(() => {
          this.runningRequests.apiChangePaymentAddress = false;
        });
    },

    /**
     * Submits a voucher code.
     */
    apiSubmitVoucher(payload: SubmitVoucherPayload): AxiosPromise {
      this.runningRequests.apiSubmitVoucher = true;

      return this.$api.post(this.$api.getApiUrl('voucher'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiSubmitVoucher'))
        .finally(() => {
          this.runningRequests.apiSubmitVoucher = false;
        });
    },

    /**
     * Adds a wishlist to the cart.
     */
    apiAddWishlistToCart(payload: AddWishlistToCartPayload): AxiosPromise {
      this.runningRequests.apiAddWishlistToCart = true;

      return this.$api.put(this.$api.getApiUrl('cartWishlist'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiAddWishlistToCart'))
        .finally(() => {
          this.runningRequests.apiAddWishlistToCart = false;
        });
    },

    /**
     * Adds an order to the cart.
     */
    apiAddOrderToCart(payload: AddOrderToCartPayload): AxiosPromise {
      this.runningRequests.apiAddOrderToCart = true;

      return this.$api.put(this.$api.getApiUrl('orderCart'), payload, {
        headers: {
          sendCheckoutData: true,
        },
      }).then(response => this.handleCheckoutDataResponse(response, 'checkout/apiAddOrderToCart'))
        .finally(() => {
          this.runningRequests.apiAddOrderToCart = false;
        });
    },

    apiCalculatePrices(): AxiosPromise {
      this.runningRequests.apiCalculatePrices = true;

      return this.$api.get(this.$api.getApiUrl('calculatePrices'), {
        headers: {
          sendCheckoutData: true,
        },
      }).then((response) => {
        const { cart } = response.data.data?.checkoutData || {};

        if (cart?.type === HybrisType.Cart) {
          this.cart = cart;

          return Promise.resolve(response);
        }

        return Promise.reject(new Error('API failure during "checkout/apiCalculatePrices" request.'));
      }).finally(() => {
        this.runningRequests.apiCalculatePrices = false;
      });
    },

    apiFetchRetailers(payload: RetailConnectPayload): AxiosPromise {
      this.runningRequests.apiFetchRetailers = true;

      return this.$api.post(this.$api.getApiUrl('retailConnectRetailers'), payload).then((response) => {
        const { result, retailers } = response.data || {};

        if (result === 'success' && Array.isArray(retailers)) {
          return response;
        }

        return Promise.reject(new Error(`API failure during "${storeName}/retailConnect" request.`));
      }).finally(() => {
        this.runningRequests.apiFetchRetailers = false;
      });
    },

    apiInitializeDatatrans(): AxiosPromise {
      this.runningRequests.apiInitializeDatatrans = true;

      return this.$api.get(this.$api.getApiUrl('initializeDatatrans')).finally(() => {
        this.runningRequests.apiInitializeDatatrans = false;
      });
    },

    apiClearCart(replaceCheckoutData = true): AxiosPromise | Promise<void> {
      const { entries } = this.cart || {};

      if (!entries?.length) {
        return Promise.resolve();
      }

      const payload = {
        entries: entries.map(entry => ({
          entryNumber: entry.entryNumber,
          product: {
            code: entry.product.code,
          },
        })),
      };

      this.runningRequests.apiClearCart = true;

      return this.$api.delete(this.$api.getApiUrl('cart'), {
        headers: {
          sendCheckoutData: true,
        },
        data: payload,
      }).then((response) => {
        if (replaceCheckoutData) {
          return this.handleCheckoutDataResponse(response, 'checkout/apiClearCart');
        }

        return response;
      }).finally(() => {
        this.runningRequests.apiClearCart = false;
      });
    },
  },
});
