import { createI18n, IntlDateTimeFormat } from 'vue-i18n';

const fallbackLocale = 'de-CH';
const fallbackMessages = __TRANSLATIONS_DE_CH__;

export const PAGE_LANG = document?.documentElement?.lang;
export const I18N_LOCALES = [fallbackLocale, 'fr-CH', 'it-CH', 'en-CH'];

const datetimeFormats: IntlDateTimeFormat = {
  month: { // January, February, March, ...
    month: 'long',
  },
  weekday: { // Monday, Tuesday, Wednesday, ...
    weekday: 'long',
  },
  weekdayNarrow: { // M, T, W, ...
    weekday: 'narrow',
  },
};

// Add styleguide only translations
if (import.meta.env.MODE !== 'production') {
  const styleguideTranslations = import.meta
    .glob('./styleguide/translations.json', {
      eager: true,
      import: 'default',
    })['./styleguide/translations.json'] as Record<string, object>;

  if (styleguideTranslations[fallbackLocale]) {
    Object.entries(styleguideTranslations[fallbackLocale]).forEach(([key, value]) => {
      // @ts-ignore Needed because typescript cannot assign index.
      fallbackMessages[key] = value;
    });
  }
}

type ValidLocale = 'de-CH' | 'fr-CH' | 'it-CH' | 'en-CH';

const i18n = createI18n<[typeof fallbackMessages], ValidLocale>({
  legacy: true, // Inject translation methods
  locale: fallbackLocale,
  fallbackLocale,
  datetimeFormats: {
    'de-CH': datetimeFormats,
    'fr-CH': datetimeFormats,
    'it-CH': datetimeFormats,
    'en-CH': datetimeFormats,
  },

  warnHtmlInMessage: import.meta.env.MODE !== 'production' ? 'error' : 'off',

  /**
   * Callback for the 'missing' event, during translation lookup.
   */
  missing(locale, messageKey) {
    console.error(`No '${locale}' translations found for '${messageKey}'`); // eslint-disable-line no-console
  },
  messages: {
    'de-CH': fallbackMessages,
    'fr-CH': __TRANSLATIONS_FR_CH__,
    'it-CH': __TRANSLATIONS_IT_CH__,
    'en-CH': __TRANSLATIONS_EN_CH__,
  },
});

export default i18n;

/**
 * Load messages for given locale if not already loaded.
 */
export const i18nLoadMessages = (locale: string): Promise<string> => Promise.resolve(locale);

/**
 * Sets the application locale to the given value.
 * Loads locale messages if needed.
 */
export const i18nSetLocale = (locale: string): Promise<void> => { // eslint-disable-line no-param-reassign
  if (!I18N_LOCALES.includes(locale)) {
    locale = fallbackLocale;
  }

  if (i18n.global.locale !== locale) {
    return i18nLoadMessages(locale).then((newLocale) => {
      import('../stores/plugins/api').then((module) => {
        module.axiosInstance.defaults.headers.common.locale = newLocale;
      });

      i18n.global.locale = newLocale as ValidLocale;
    });
  }

  return Promise.resolve();
};

i18nSetLocale(PAGE_LANG || fallbackLocale);
