<template>
  <div :class="b()"></div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/product';

  // type Setup = {}

  // type Data = {}

  /**
   * Renders oxomi item pages.
   */
  export default defineComponent({
    name: 'c-oxomi-item-pages',

    // components: {},

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {},

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.$oxomi().then(() => {
        const { code, supplierCode } = this.product || {};
        const { supplierNumber } = this.product.brand || {};

        window.oxomi.itemPages({
          supplierItemNumber: supplierNumber === 'own' ? code : supplierCode,
          supplierNumber,
          showDetails: true,
          lang: this.$i18n.locale.split('-')[0],
          target: this.$el,
        });
      });
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-oxomi-item-pages {
    .oxomi-catalog-title {
      @include mixins.font(variables.$font-size--16, variables.$line-height--18);
    }

    .oxomi-catalog-container {
      height: auto !important; // stylelint-disable-line declaration-no-important
    }
  }
</style>
