<template>
  <Transition name="c-modal--fade-animation"
              @before-enter="onBeforeEnter"
              @after-enter="onAfterEnter"
              @after-leave="onAfterLeave"
  >
    <dialog v-if="isOpen"
            :class="b(modifiers)"
    >
      <div v-outside-click="onOutsideClick"
           :class="b('inner')"
      >
        <c-notification-container v-if="showGlobalNotifications" :class="b('notifications')" />
        <div v-if="$slots.head || title || isClosable" :class="b('header')">
          <div :class="b('header-inner')">
            <slot name="head" :close="close">
              <template v-if="title">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h2 v-if="htmlTitle" v-html="title" :class="b('title')"></h2>
                <h2 v-else :class="b('title')">
                  {{ title }}
                </h2>
              </template>

              <button v-if="isClosable"
                      :aria-label="$t('c-modal.buttonClose')"
                      :class="b('button-close')"
                      type="button"
                      @click="close"
              >
                <e-icon icon="i-close" size="18" />
              </button>
            </slot>
          </div>
        </div>
        <div :class="b('content')">
          <slot></slot>
        </div>
        <div v-if="$slots.stickyFooter"
             ref="stickyFooter"
             :class="b('sticky-footer')"
        >
          <slot name="stickyFooter"></slot>
        </div>
      </div>
    </dialog>
  </Transition>
</template>

<script lang="ts">
  import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
  import { defineComponent } from 'vue';
  import useNotificationStore from '@/stores/notification';
  import useSessionStore from '@/stores/session';
  import propScale from '@/helpers/prop.scale';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';
  import eIcon from '@/elements/e-icon.vue';
  import cNotificationContainer from '@/components/c-notification-container.vue';
  import useUuid, { Uuid } from '@/compositions/uuid';
  import { bodyScrollOptions } from '@/setup/options';

  interface Setup extends Uuid {
    notificationStore: ReturnType<typeof useNotificationStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  /**
   * Renders a modal dialog.
   *
   * Based on https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog.
   *
   * **WARNING: uses 'v-html' for the 'title'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-modal',

    components: {
      cNotificationContainer,
      eIcon,
    },

    props: {
      /**
       * Toggles the visibility of the modal.
       */
      isOpen: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining a title.
       */
      title: {
        type: String,
        default: null,
      },

      /**
       * Allows rendering of html in the title.
       */
      htmlTitle: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows defining whether the modal is closable.
       */
      isClosable: {
        type: Boolean,
        default: true,
      },

      /**
       * Allows enabling/disabling close on outside click.
       */
      closeOnOutsideClick: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows modifying the size of the modal.
       *
       * Valid values: `[400, 600]`
       */
      size: propScale(600, [
        400,
        600,
        800,
        950,
      ]),

      /**
       * Allows modifying the inner spacing of the modal.
       *
       * Valid values: `[300]`
       */
      spacing: propScale(300, [
        0,
        300,
      ]),

      /**
       * Allows enabling display of global notifications in the modal.
       */
      showGlobalNotifications: {
        type: Boolean,
        default: true,
      },
    },
    emits: {
      'update:isOpen': (state: unknown): boolean => typeof state === 'boolean',
      'open': () => true,
      'close': () => true,
    },

    setup(): Setup {
      return {
        notificationStore: useNotificationStore(),
        sessionStore: useSessionStore(),
        ...useUuid(),
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      /**
       * Returns modifier classes.
       */
      modifiers(): Modifiers {
        return {
          size: this.size,
          spacing: this.spacing,
        };
      },

      isModalStackedOnTop(): boolean {
        return this.sessionStore.openModalStack[0] === this.uuid;
      },
    },
    watch: {

      /**
       * Triggers opening/closing modal.
       */
      isOpen(state: boolean): void {
        if (state) {
          this.open();
        } else {
          this.close();
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      if (this.isOpen) {
        this.open();
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Opens the modal.
       */
      open(): void {
        this.$nextTick(() => {
          this.$el.showModal(); // Native function of `HTMLDialogElement`
          this.$emit('update:isOpen', true);
          this.sessionStore.openModalStack.unshift(this.uuid);
        });
      },

      /**
       * Closes the modal.
       */
      close(): void {
        if (!this.isClosable) {
          return;
        }

        if (this.isOpen) {
          this.$emit('update:isOpen', false);
          this.sessionStore.openModalStack = this.sessionStore.openModalStack.filter(uuid => uuid !== this.uuid);
        }

        this.$emit('close');
      },

      /**
       * Handler for keypress events.
       */
      onKeyDown(event: KeyboardEvent): void {
        if (this.isOpen && event.code === 'Escape') {
          if (!this.isClosable) {
            event.preventDefault(); // Prevents browser closing `<dialog>`.
          }

          this.close();
        }
      },

      /**
       * Handler for outside click event.
       */
      onOutsideClick(): void {
        if (this.closeOnOutsideClick && this.isOpen && this.isModalStackedOnTop) {
          this.close();
        }
      },

      onBeforeEnter(element: Element): void {
        disableBodyScroll(
          element.querySelector(`.${this.b('inner')}`) as HTMLDivElement,
          bodyScrollOptions
        );
      },

      /**
       * Handler for when the modal open-animation is completed.
       */
      onAfterEnter(): void {
        this.$emit('open');
        document.addEventListener('keydown', this.onKeyDown);

        if (this.showGlobalNotifications) {
          this.notificationStore.showDefaultGlobalNotifications = false;
        }
      },

      /**
       * Handler for when the modal close-animation is completed.
       */
      onAfterLeave(element: Element): void {
        enableBodyScroll(element.querySelector(`.${this.b('inner')}`) as HTMLDivElement);
        document.removeEventListener('keydown', this.onKeyDown);

        if (this.showGlobalNotifications) {
          this.notificationStore.showDefaultGlobalNotifications = true;
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use 'sass:math';
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins' as *;
  // @use '../setup/scss/extends' as *;
  // @use '../setup/scss/functions' as *;

  .c-modal {
    $this: &;

    max-width: 100vw;
    padding: 0;
    overflow-x: hidden;
    border: none;
    background: none;

    @include media($down: sm) {
      height: 100dvh;
      max-height: none;
    }

    &::backdrop {
      opacity: 0.75;
      background-color: variables.$color-grayscale--0;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100%;
      overflow-y: auto;
      background-color: variables.$color-grayscale--1000;
      justify-self: center;

      @include media(md) {
        display: block;
        align-self: center;
        max-width: 75vw;
        height: auto;
        overflow-y: hidden;
      }

      @include media(lg) {
        max-width: 66vw;
      }

      @include media(xl) {
        max-width: 66vw;
      }
    }

    &__header {
      padding: variables.$spacing--20 variables.$spacing--20 0;
    }

    &__header-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: variables.$spacing--20;
    }

    &__content {
      flex: 1 0 auto;
      padding: variables.$spacing--30 variables.$spacing--20;
    }

    &__sticky-footer {
      padding: variables.$spacing--20;

      @include media($down: sm) {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 2px solid variables.$color-grayscale--0;
        background-color: variables.$color-grayscale--1000;
      }
    }

    &__title {
      margin: variables.$spacing--20 0 0;
    }

    &__button-close {
      margin-left: auto;
      cursor: pointer;
    }

    &--size-400 &__inner {
      @include media(md) {
        width: 450px;
      }
    }

    &--size-600 &__inner {
      @include media(md) {
        width: 600px;
      }
    }

    &--size-800 &__inner {
      @include media(md) {
        width: 800px;
      }
    }

    &--size-950 &__inner {
      @include media(md) {
        width: 95vw;
        max-width: 1460px;
      }
    }

    &--spacing-0 &__content {
      padding-right: 0;
      padding-left: 0;
    }

    &__notifications {
      @include z-index(globalNotification);

      position: sticky;
      top: 0;
    }
  }

  .c-modal--fade-animation-enter-active,
  .c-modal--fade-animation-leave-active {
    &,
    &::backdrop {
      transition: opacity 200ms ease-in-out;
    }
  }

  .c-modal--fade-animation-enter-from,
  .c-modal--fade-animation-leave-to {
    &,
    &::backdrop {
      opacity: 0;
    }
  }
</style>
