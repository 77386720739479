<template>
  <c-modal v-model:is-open="isOpen"
           :class="b()"
           :title="title"
           html-title
           close-on-outside-click
  >
    <e-loader v-if="runningRequests.apiFetchWishlistsByProduct || runningRequests.apiFetchWishlists" />
    <c-tabs v-else :tabs="tabs" padding="400">
      <template v-for="tab in tabs" :key="tab.id" #[tab.id]>
        <form v-if="tab.wishlistType === WishlistType.UserWishlist || isCompanyWishlistAdmin"
              :class="b('form')"
              @submit.prevent="createWishlist(tab.wishlistType)"
        >
          <h4 :class="b('form-title')">
            {{ $t('c-wishlist-modal.createWishlistTitle') }}
          </h4>
          <e-input v-model="form.name"
                   :aria-label="$t('c-wishlist-modal.nameInputLabel')"
                   name="name"
          />
          <e-button :aria-label="$t('global.add')"
                    :title="$t('global.add')"
                    :progress="runningRequests.apiAddWishlist"
                    :disabled="!form.name"
                    type="submit"
                    height="200"
          >
            <e-icon icon="i-add" size="22" />
          </e-button>
        </form>
        <e-loader v-if="wishlistStore.runningRequests.apiFetchWishlists" />
        <ul v-else :class="b('list')">
          <li v-for="wishlist in tab.wishlists" :key="wishlist.code" :class="b('list-item')">
            <e-loader v-if="runningRequests.updateWishlistAssignment.includes(wishlist.code)" />
            <e-checkbox :model-value="isWishlistSelected(wishlist)"
                        :name="`toggle-${wishlist.code}`"
                        :disabled="tab.wishlistType === WishlistType.CompanyWishlist && !isCompanyWishlistAdmin"
                        value
                        @change="(value, isChecked) => updateWishlistAssignment(isChecked, wishlist)"
            >
              {{ wishlist.name }}
            </e-checkbox>
          </li>
        </ul>
      </template>
    </c-tabs>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import useWishlistStore, { WishlistType } from '@/stores/wishlist';
  import useSessionStore from '@/stores/session';
  import cTabs, { Tab } from '@/components/c-tabs.vue';
  import cModal from '@/components/c-modal.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eButton from '@/elements/e-button.vue';
  import eCheckbox from '@/elements/e-checkbox.vue';
  import eInput from '@/elements/e-input.vue';
  import eLoader from '@/elements/e-loader.vue';
  import { PermissionGroupUid } from '@/setup/globals';
  import { DateFormat } from '@/plugins/dayjs';
  import { Wishlist } from '@/types/wishlist';
  import { Product } from '@/types/product';

  enum TabId {
    CompanyWishlists = 'companyWishlists',
    UserWishlists = 'userWishlists',
  }

  interface WishlistTab extends Tab {
    wishlists: Wishlist[];
    wishlistType: WishlistType;
  }

  interface Setup {
    wishlistStore: ReturnType<typeof useWishlistStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    WishlistType: typeof WishlistType;
  }

  interface Data {
    companyWishlists: Wishlist[];
    userWishlists: Wishlist[];
    form: {
      name: string;
    };
    runningRequests: {
      apiAddWishlist: boolean;
      updateWishlistAssignment: string[];
      apiFetchWishlistsByProduct: boolean;
      apiFetchWishlists: boolean;
    };
  }

  export enum WishlistModalMode {
    'Product',
    'Cart',
  }

  /**
   * Renders the wishlist modal.
   */
  export default defineComponent({
    name: 'c-wishlist-modal',

    components: {
      eLoader,
      eButton,
      eIcon,
      eCheckbox,
      cModal,
      cTabs,
      eInput,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        wishlistStore: useWishlistStore(),
        sessionStore: useSessionStore(),
        WishlistType,
      };
    },
    data(): Data {
      return {
        companyWishlists: [],
        userWishlists: [],
        form: {
          name: '',
        },
        runningRequests: {
          apiAddWishlist: false,
          updateWishlistAssignment: [],
          apiFetchWishlistsByProduct: false,
          apiFetchWishlists: false,
        },
      };
    },

    computed: {
      mode(): WishlistModalMode | undefined {
        return this.wishlistStore.wishlistModalMode;
      },

      title(): string | undefined {
        return this.mode === WishlistModalMode.Product
          ? this.product?.name || ''
          : this.$t('c-wishlist-modal.addCartToWishlistTitle');
      },

      product(): Product | undefined {
        return this.wishlistStore.wishlistModalProduct;
      },

      isOpen: {
        get(): boolean {
          return this.mode !== undefined;
        },

        set(isOpen: boolean): void {
          if (!isOpen) {
            this.close();
          }
        },
      },

      tabs(): WishlistTab[] {
        const tabs: WishlistTab[] = [];
        const { companyWishlists, userWishlists } = this;

        tabs.push({
          title: this.$t('l-wishlist-overview.tabUserWishlists', { amount: userWishlists.length }),
          id: TabId.UserWishlists,
          active: true,
          wishlists: userWishlists,
          wishlistType: WishlistType.UserWishlist,
        });

        if (this.sessionStore.getIsPermissionGroupSelected(PermissionGroupUid.CompanyWishlistRead)) {
          tabs.push({
            title: this.$t('l-wishlist-overview.tabCompanyWishlists', { amount: companyWishlists.length }),
            id: TabId.CompanyWishlists,
            wishlists: companyWishlists,
            wishlistType: WishlistType.CompanyWishlist,
          });
        }

        return tabs;
      },

      isCompanyWishlistAdmin(): boolean {
        return this.sessionStore.getIsPermissionGroupSelected(PermissionGroupUid.CompanyWishlistAdmin);
      },
    },
    watch: {
      isOpen(isOpen: boolean): void {
        if (isOpen) {
          const { product } = this;

          if (this.mode === WishlistModalMode.Product && product) {
            this.runningRequests.apiFetchWishlistsByProduct = true;

            this.wishlistStore.apiFetchWishlistsByProduct(product.code).then((response) => {
              const { userWishlists, companyWishlists } = response.data?.data || {};

              this.userWishlists = userWishlists || [];
              this.companyWishlists = companyWishlists || [];
            }).finally(() => {
              this.runningRequests.apiFetchWishlistsByProduct = false;
            });
          } else {
            this.runningRequests.apiFetchWishlists = true;

            this.wishlistStore.apiFetchWishlists().then((response) => {
              const { userWishlists, companyWishlists } = response.data?.data || {};

              this.userWishlists = userWishlists || [];
              this.companyWishlists = companyWishlists || [];
            }).finally(() => {
              this.runningRequests.apiFetchWishlists = false;
            });
          }
        }
      },

      mode(mode: WishlistModalMode): void {
        if (mode === WishlistModalMode.Cart) {
          const date = this.$dayjs().format(DateFormat.dddd_DD_MMMM_YYYY_HH_mm);

          this.form.name = this.$t('c-wishlist-modal.defaultName', { date });
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      close(): void {
        this.wishlistStore.wishlistModalProduct = undefined;
        this.wishlistStore.wishlistModalMode = undefined;
        this.wishlistStore.wishlistModalQuantity = undefined;
      },

      isWishlistSelected(wishlist: Wishlist): boolean {
        const { entries } = wishlist;
        const { product } = this;

        if (this.wishlistStore.wishlistModalMode === WishlistModalMode.Cart || !product) {
          return false;
        }

        return !!wishlist.includesProduct || !!entries?.some(entry => entry.product.code === product.code);
      },

      updateWishlistAssignment(add: boolean, wishlist: Wishlist): void {
        if (this.mode === WishlistModalMode.Cart) {
          this.wishlistStore.apiAddCartToWishlist(wishlist.code).finally(() => {
            this.close();
          });

          return;
        }

        const { code } = this.product || {};

        if (!code) {
          return;
        }

        this.runningRequests.updateWishlistAssignment.push(wishlist.code);

        if (add) {
          this.wishlistStore.apiAddProductToWishlist({
            product: {
              code,
            },
            wishlistCode: wishlist.code,
            commissionId: this.sessionStore.globalCommission?.id || '',
            quantity: this.wishlistStore.wishlistModalQuantity || 1,
          }).finally(() => {
            this.runningRequests.updateWishlistAssignment = this.runningRequests.updateWishlistAssignment
              .filter(wishlistCode => wishlistCode !== wishlist.code);
          });
        } else {
          this.wishlistStore.apiRemoveProductFromWishlist({
            wishlistCode: wishlist.code,
            product: {
              code,
            },
          })
            .finally(() => {
              this.runningRequests.updateWishlistAssignment = this.runningRequests.updateWishlistAssignment
                .filter(wishlistCode => wishlistCode !== wishlist.code);
            });
        }
      },

      createWishlist(wishlistType: WishlistType): void {
        const { code } = this.product || {};
        const { name } = this.form;

        if (!name) {
          return;
        }

        if (this.mode === WishlistModalMode.Cart) {
          this.wishlistStore.apiAddCartToNewWishlist(name, wishlistType).finally(() => {
            this.close();
          });

          return;
        }

        if (!code || !name) {
          return;
        }

        this.runningRequests.apiAddWishlist = true;

        this.wishlistStore.addProductToNewWishlist({
          name,
          wishlistType,
          entries: [
            {
              product: {
                code,
              },
              commissionId: this.sessionStore.globalCommission?.id || '',
              quantity: this.wishlistStore.wishlistModalQuantity,
            },
          ],
        }).then((response) => {
          const { userWishlists, companyWishlists } = response.data?.data || {};

          this.userWishlists = userWishlists || [];
          this.companyWishlists = companyWishlists || [];
          this.form.name = '';
        }).finally(() => {
          this.runningRequests.apiAddWishlist = false;
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-wishlist-modal {
    &__form {
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 1fr auto;
      align-items: end;
      margin-block: variables.$spacing--20 variables.$spacing--50;
    }

    &__form-title {
      grid-column: span 2;
      margin: 0;
    }

    &__list {
      display: grid;
      grid-row-gap: variables.$spacing--10;
    }

    &__list-item {
      position: relative;
      justify-self: start;

      .e-loader {
        @include mixins.z-index(front);

        position: absolute;
        top: 50%;
        left: variables.$spacing--5;
        transform: translateY(-50%);
        font-size: 5px;
      }

      .e-loader + .e-checkbox {
        pointer-events: none;

        .e-checkbox__indicator {
          visibility: hidden;
        }
      }
    }

    .c-tabs__tab {
      @include mixins.media(md) {
        @include mixins.font-size(variables.$font-size--25);
      }
    }
  }
</style>
