<!-- eslint-disable vue/max-len -->
<template>
  <div :class="b()">
    <div v-if="!$viewport.isMd" :class="b('mobile-profile')">
      <button v-if="sessionStore.getIsLoggedIn"
              :class="b('mobile-profile-button', { isUserLoggedIn : isMobileAccountNavigationVisible && sessionStore.getIsLoggedIn })"
              :aria-label="$t('c-service-navigation.labelProfile')"
              type="button"
              @click="$emit('clickAccountNavigationButton')"
      >
        <e-icon icon="i-user-logged-in" size="20" />
        {{ $t('c-service-navigation.labelProfile') }}
        <e-icon icon="i-arrow--down" size="15" :class="b('icon-arrow-down')" />
      </button>
      <!-- Class "app-login-link" required by wrapper app -->
      <a
        v-else-if="showLoginLink"
        :href="sessionStore.links.loginLogoutLink.url"
        :class="b('mobile-profile-login-link')"
        class="app-login-link"
        @click="handleLoginButtonClick"
      >
        <e-icon icon="i-user" size="25" />
        {{ $t('c-service-navigation.linkLogin') }}
      </a>
    </div>
    <ul :class="b('list')">
      <li v-if="isValidDeviceForBarcodeScanner() && !sessionStore.getIsPunchoutOrOciUser"
          :class="b('item')"
      >
        <c-barcode-scanner :mode="Mode.Search" :class="b('barcode-scanner')" />
      </li>
      <li :class="b('item')">
        <a :href="addContextPathToUrl(sessionStore.links.contactPageLink.url)"
           :class="b('link', { icon: true })"
        >
          <e-icon v-if="!$viewport.isMd" icon="i-contact-page" size="20" />
          <span :class="b('link-label')">
            {{ $t('c-service-navigation.linkContact') }}
          </span>
        </a>
      </li>
      <li v-if="sessionStore.links.storefinderLink.visible"
          :class="b('item')"
      >
        <a :href="addContextPathToUrl(sessionStore.links.storefinderLink.url)"
           :class="b('link', { icon: true, favoriteStore: !!pointOfService })"
        >
          <e-icon v-if="!$viewport.isMd" icon="i-map-pin" size="20" />
          <span :class="b('link-label')">
            {{ storeFinderLabel }}
          </span>
        </a>
      </li>
      <li v-if="thirdPartyInterfaceLink" :class="b('item')">
        <a :href="thirdPartyInterfaceLink"
           :class="b('link', { thirdPartyInterface: true })"
           @click="onClickThirdPartyInterfaceLink"
        >
          <e-icon icon="i-arrow-2--left" size="20" />
          <span :class="b('link-label')">
            {{ $t('c-service-navigation.linkBackToThirdPartyInterface') }}
          </span>
        </a>
      </li>
      <li v-else-if="$viewport.isMd && !sessionStore.getIsLoggedIn && showLoginLink" :class="b('item')">
        <!-- Class "app-login-link" required by wrapper app -->
        <a :href="sessionStore.links.loginLogoutLink.url"
           :class="b('link')"
           class="app-login-link"
           @click="handleLoginButtonClick"
        >
          <span :class="b('link-label')">
            {{ $t('c-service-navigation.linkLogin') }}
          </span>
        </a>
      </li>
      <li v-if="sessionStore.getIsLoggedIn" :class="b('item', { iconOnly: true })">
        <button :id="b('contact-person-toggle')"
                :class="b('button')"
                :aria-label="$t('c-service-navigation.labelContactPerson')"
                type="button"
                @click="$emit('clickContactPersonButton')"
        >
          <e-icon icon="i-phone" size="20" />
        </button>
      </li>
      <li v-if="sessionStore.getIsLoggedIn && $viewport.isMd" :class="b('item', { iconOnly: true })">
        <!-- ID used for outside click handling in `c-account-navigation` -->
        <button :id="b('account-navigation-toggle')"
                :aria-label="$t('c-service-navigation.labelProfile')"
                :class="b('button')"
                type="button"
                @click="$emit('clickAccountNavigationButton')"
        >
          <e-icon icon="i-user-logged-in" size="20" />
        </button>
      </li>
      <li :class="b('item', { languageSwitcher: true, isServiceNavigationLanguageSwitcherHidden: sessionStore.getIsLoggedIn })">
        <c-language-switcher />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import useSessionStore from '@/stores/session';
  import useCheckoutStore from '@/stores/checkout';
  import { PointOfService } from '@/types/point-of-service';
  import eIcon from '@/elements/e-icon.vue';
  import cLanguageSwitcher from '@/components/c-language-switcher.vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import isValidDeviceForBarcodeScanner from '@/helpers/is-valid-device-for-barcode-scanner';
  import { isOpen as isCartMergeModalOpen } from '@/components/c-cart-merge-modal.vue';
  import cBarcodeScanner, { Mode } from '@/components/c-barcode-scanner.vue';

  interface Setup {
    sessionStore: ReturnType<typeof useSessionStore>;
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
    isValidDeviceForBarcodeScanner: typeof isValidDeviceForBarcodeScanner;
    Mode: typeof Mode;
  }

  // interface Data {}

  /**
   * Service navigation which is displayed above the main navigation.
   */
  export default defineComponent({
    name: 'c-service-navigation',

    components: {
      cBarcodeScanner,
      cLanguageSwitcher,
      eIcon,
    },

    props: {
      /**
       * Expects the visibility state of the account navigation.
       */
      isMobileAccountNavigationVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: {
      clickContactPersonButton: () => true,
      clickAccountNavigationButton: () => true,
    },

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        checkoutStore: useCheckoutStore(),
        addContextPathToUrl,
        isValidDeviceForBarcodeScanner,
        Mode,
      };
    },
    // data(): Data {
    //   return {};
    // },
    computed: {
      /**
       * Returns the users chosen point of service.
       */
      pointOfService(): PointOfService | null {
        return this.sessionStore.user.defaultPos || null;
      },

      /**
       * For Punchout- and OCI-Users we show a link for returning to the interface.
       */
      thirdPartyInterfaceLink(): string | undefined {
        const { flags, thirdPartyUrls } = this.sessionStore;
        const { isOciUser, isPunchoutUser } = flags;

        if (isOciUser) {
          return thirdPartyUrls.ociLogoutUrl;
        }

        if (isPunchoutUser) {
          return thirdPartyUrls.punchoutCancel;
        }

        return undefined;
      },

      storeFinderLabel(): string {
        const { pointOfService } = this;

        if (this.sessionStore.flags.storeSelectionEnabled && pointOfService?.displayName) {
          return pointOfService.displayName;
        }

        return this.$t('c-service-navigation.linkChoosePickupStore');
      },

      showLoginLink(): boolean {
        return import.meta.env.VITE_LOGIN_LINK_POSITION === 'default';
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      handleLoginButtonClick(event: MouseEvent): void {
        if (this.checkoutStore.getCartEntriesAmount > 0) {
          event.preventDefault();
          isCartMergeModalOpen.value = true;
        }
      },

      /**
       * When a user returns to the OCI interface we clear the cart beforehand.
       */
      onClickThirdPartyInterfaceLink(event: Event): void {
        const { flags, thirdPartyUrls } = this.sessionStore;

        if (flags.isOciUser) {
          event.preventDefault();

          this.sessionStore.showGlobalLoader();

          this.checkoutStore.apiClearCart(false).finally(() => {
            this.sessionStore.hideGlobalLoader();
            window.location.href = thirdPartyUrls.ociLogoutUrl;
          });
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-service-navigation {
    $this: &;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-bottom: variables.$spacing--5;
    border-bottom: 2px solid variables.$color-grayscale--0;

    @include mixins.media(md) {
      grid-template-columns: 1fr;
      padding-bottom: 0;
      border-bottom: none;
    }

    &__list {
      display: flex;
      align-items: center;
      height: 30px;
      column-gap: variables.$spacing--20;

      @include mixins.media(md) {
        column-gap: variables.$spacing--30;
      }
    }

    &__item {
      @include mixins.font(variables.$font-size--16);

      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: - variables.$spacing--10;
        content: '';
        width: 1px;
        height: 100%;
        background-color: variables.$color-grayscale--0;
        transform: translateY(-50%);

        @include mixins.media(md) {
          left: - variables.$spacing--15;
          width: 2px;
          height: 1em;
        }
      }

      &:first-of-type::before {
        @include mixins.media($down: sm) {
          display: none;
        }
      }
    }

    &__item--language-switcher {
      @include mixins.media(md) {
        order: -1;
      }

      &::before {
        @include mixins.media(md) {
          display: none;
        }
      }
    }

    &__item--is-service-navigation-language-switcher-hidden {
      @include mixins.media($down: sm) {
        display: none;
      }
    }

    &__item--icon-only {
      @include mixins.media(sm) {
        align-self: center;
      }
    }

    &__button,
    &__link {
      display: flex;
      align-items: center;
    }

    &__link {
      border-bottom: 2px solid transparent;

      &:hover,
      &:focus {
        border-color: variables.$color-primary--1;
      }
    }

    &__link--icon {
      @include mixins.media($down: sm) {
        border: none;
      }

      &:hover,
      &:focus {
        @include mixins.media($down: sm) {
          color: variables.$color-primary--1;
        }
      }
    }

    &__link--third-party-interface {
      display: flex;
      column-gap: variables.$spacing--5;

      &,
      &:hover,
      &:focus {
        color: variables.$color-primary--1;
        font-weight: variables.$font-weight--bold;
      }
    }

    &__link-label {
      @include mixins.media($down: sm) {
        @include mixins.invisible();
      }
    }

    &__button {
      cursor: pointer;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__mobile-profile-button,
    &__mobile-profile-login-link {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      display: flex;
      align-items: center;
      column-gap: variables.$spacing--10;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__mobile-profile-button {
      cursor: pointer;

      .e-icon {
        transition: transform variables.$transition-duration--200 linear;
      }

      &--is-user-logged-in {
        #{$this}__icon-arrow-down {
          transform: rotate(180deg);
          color: variables.$color-grayscale--0;
        }
      }
    }

    &__icon-arrow-down {
      margin-top: 0.3em; // Shift icon
    }

    &__barcode-scanner {
      display: flex;
    }

    .c-barcode-scanner__trigger .e-icon {
      width: 20px;
      height: 20px;
    }
  }
</style>
