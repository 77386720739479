import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { Plugin } from 'vue';
import { PAGE_LANG } from '@/setup/i18n';
// eslint-disable-next-line id-length

const LOCALES = ['en', 'fr', 'it', 'de'];

/**
 * Holds the date format templates used with dayjs.
 */
/* eslint-disable @typescript-eslint/naming-convention */
export enum DateFormat {
  DD_MM_YYYY = 'DD.MM.YYYY',
  DD_MM_YYYY_HH_mm = 'DD.MM.YYYY HH:mm',
  dddd_DD_MMMM_YYYY = 'dddd [/] DD. MMMM YYYY',
  dddd_DD_MMMM_YYYY_HH_mm = 'dddd [/] DD. MMMM YYYY HH:mm',
  HH_mm = 'HH:mm',
}
/* eslint-enable @typescript-eslint/naming-convention */

const plugin: Plugin = {
  install(app) {
    const locale = PAGE_LANG.split('-')[0];

    if (LOCALES.includes(locale)) {
      dayjs.locale(locale);
    } else {
      dayjs.locale('de');
    }

    dayjs.extend(customParseFormat);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isBetween);

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$dayjs = dayjs;
  },
};

export default plugin;
